import React, { lazy } from "react";

const LazyModifyTranslationRequest = lazy(
  () => import("./ModifyTranslationRequest")
);

const ModifyTranslationRequest = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => <LazyModifyTranslationRequest {...props} />;

export default ModifyTranslationRequest;
