import React from "react";
import { useAppSelector } from "app/store";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import Box from "@amzn/awsui-components-react/polaris/box";
import ApiIndicator from "components/api-indicator";
import { useGetTemplateIdsQuery } from "features/templateIds";
import { useNavConfig, useOnFollowHandler } from "./hooks";

const LeftNavigation: React.FC = () => {
  const onFollowHandler = useOnFollowHandler();
  const { header, items, activeHref } = useNavConfig();
  const { alias } = useAppSelector((state) => state.auth.user);
  const { error, isLoading, isError } = useGetTemplateIdsQuery({
    userAlias: alias,
  });

  return (
    <>
      <SideNavigation
        header={header}
        items={items}
        activeHref={activeHref}
        onFollow={onFollowHandler}
        data-testid="navigation"
      />
      <Box margin={{ left: "l" }}>
        <ApiIndicator
          isLoading={isLoading}
          isError={isError}
          isAlertError={true}
          error={error}
        />
      </Box>
    </>
  );
};

export default LeftNavigation;
