import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "@aws-amplify/auth";
import { RootState } from "app/store";

export const authenticate = createAsyncThunk(
  "auth/authenticate",
  async (arg, { getState }) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const userAttributes = JSON.parse(JSON.stringify(cognitoUser.attributes));
      const userIdentity = JSON.parse(userAttributes.identities)[0];
      const userSession = JSON.parse(
        JSON.stringify(cognitoUser.signInUserSession)
      );
      const idToken = userSession.idToken;

      return {
        isAuthenticated: true,
        jwtToken: idToken.jwtToken,
        roles: idToken.payload["cognito:groups"],
        user: {
          alias: userIdentity.userId,
          firstName: userAttributes.given_name,
          lastName: userAttributes.family_name,
          fullName: `${userAttributes.given_name} ${userAttributes.family_name}`,
          email: userAttributes.email,
        },
      };
    } catch (e) {
      const { providerName } = (getState() as RootState).settings.auth;
      Auth.federatedSignIn({ customProvider: providerName });
    }
  }
);
