import React from "react";
import { Trans } from "react-i18next";

import Link from "@amzn/awsui-components-react/polaris/link";

import { useOnFollowHandler } from "./hooks";
import { IInfoProps } from "./definitions";

const Info: React.FC<IInfoProps> = (props: IInfoProps) => {
  const onFollowHandler = useOnFollowHandler(props);

  return (
    <Link variant="info" onFollow={onFollowHandler} data-testid="info">
      <Trans>features.info.label</Trans>
    </Link>
  );
};

export default Info;
