import { createSlice } from "@reduxjs/toolkit";
import { ITimeState } from "./definitions";

const initialState: ITimeState = {
  isRelativeTimeFormat: false,
};

export const timeSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    formatToggled: (state) => {
      state.isRelativeTimeFormat = !state.isRelativeTimeFormat;
    },
  },
});

export const { formatToggled } = timeSlice.actions;

export default timeSlice.reducer;
