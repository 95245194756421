import React from "react";
import moment from "moment";
import Box from "@amzn/awsui-components-react/polaris/box";
import { ITimeProps } from "./definitions";
import { useOnDoubleClickHandler } from "./hooks";
import { useAppSelector } from "app/store";

const DEFAULT_FORMAT = "yyyy-MM-DDThh:mm:ss.SSSZ";

const Time: React.FC<ITimeProps> = (props: ITimeProps) => {
  const time = moment(props.timestamp);
  const formattedTime = time.format(props.format ?? DEFAULT_FORMAT);
  const relativeTime = time.fromNow();

  const { isRelativeTimeFormat } = useAppSelector((state) => state.time);
  const [label, setLabel] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const onDoubleClickHandler = useOnDoubleClickHandler();

  React.useEffect(() => {
    if (isRelativeTimeFormat) {
      setLabel(relativeTime);
      setTitle(formattedTime);
    } else {
      setLabel(formattedTime);
      setTitle(relativeTime);
    }
  }, [isRelativeTimeFormat, formattedTime, relativeTime]);

  return (
    <time
      style={{ cursor: "pointer" }}
      onClick={onDoubleClickHandler}
      title={title}
    >
      <Box
        variant={props.variant ?? "span"}
        fontSize={props.fontSize ?? "body-m"}
      >
        {label}
      </Box>
    </time>
  );
};

export default Time;
