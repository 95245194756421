import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IPageHeaderState } from "./definitions";

const initialState: IPageHeaderState = {
  title: "",
  description: "",
  hasInfo: false,
};

export const pageHeaderSlice = createSlice({
  name: "pageHeader",
  initialState,
  reducers: {
    pageHeaderUpdated: (state, action: PayloadAction<IPageHeaderState>) => {
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.hasInfo = action.payload.hasInfo;
      state.help = action.payload.help;
      state.actions = action.payload.actions;
    },
  },
});

export const { pageHeaderUpdated } = pageHeaderSlice.actions;

export const selectPageHeader = (state: RootState) => state.pageHeader;

export default pageHeaderSlice.reducer;
