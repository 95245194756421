export const i18nStrings = {
  overflowMenuTitleText: "Menu",
  overflowMenuTriggerText: "More",
};

export const profileActions = [
  {
    type: "menu-dropdown",
    id: "support-group",
    items: [],
  },
];
