import { mainApi } from "services/main";
import { GetTemplateIdsRequest, GetTemplateIdsResponse } from "./definitions";
import { TAGS } from "services/main/constants";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateIds: builder.query<
      GetTemplateIdsResponse,
      GetTemplateIdsRequest
    >({
      query: (request: GetTemplateIdsRequest) => ({
        url: `/v1/lts/templateIds?userAlias=${request.userAlias}`,
        method: "GET",
      }),
      providesTags: [TAGS.TemplateIds],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTemplateIdsQuery } = extendedApi;
