/**
 * Function that prepares the customized properties for the given template
 * @param {records, link}
 * @returns object to the custom properties
 */
export const getSubsectionFromGivenList = (records: string[], link: string) => {
  return records.map((record: string) => ({
    type: "link",
    text: record,
    href: `/${link}/${record}`,
  }));
};
