import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/store";
import { authenticate } from "./thunk";
import Loader from "components/loader";
import { useTranslation } from "react-i18next";

/**
 * Authentication component to render children after authenticating user
 */
const AuthN: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const authenticatingLabel = t("features.auth.authenticating");
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(authenticate());
  }, [dispatch]);

  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <Loader loadingText={authenticatingLabel} />;
  }
};

export default AuthN;
