import { useLocation, useNavigate } from "react-router-dom";
import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { useAppSelector } from "app/store";
import { useTranslation } from "react-i18next";
import { useGetTemplateIdsQuery } from "features/templateIds";
import { getSubsectionFromGivenList } from "./utils";

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, { replace: true });
    }
  };
};

export const useNavConfig = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { alias } = useAppSelector((state) => state.auth.user);
  const { data } = useGetTemplateIdsQuery({
    userAlias: alias,
  });
  let templateIds: string[] = [];
  if (data) {
    templateIds = data.templateIds;
  }

  return {
    header: {
      href: "/",
      text: t("left_nav.title"),
    } as SideNavigationProps.Header,
    items: [
      /* PLOP_INJECT_NAV_ITEM */
      {
        type: "section",
        text: "My Templates",
        items: getSubsectionFromGivenList(templateIds, "template"),
      },
    ] as SideNavigationProps.Item[],
    activeHref: location.pathname,
  };
};
