import { BreadcrumbGroupProps } from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBreadcrumbsState } from "./definitions";

const initialState: IBreadcrumbsState = {
  items: [],
};

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    breadcrumbsUpdated: (
      state,
      action: PayloadAction<BreadcrumbGroupProps.Item[]>
    ) => {
      state.items = action.payload;
    },
  },
});

export const { breadcrumbsUpdated } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
