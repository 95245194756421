import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { ILoaderProps } from "./definitions";
import styles from "./style.module.scss";

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
  return (
    <Box className={styles.loader} data-testid="loader">
      <Box>
        <Spinner size="large" variant="normal" />
      </Box>
      {props.loadingText && <Box>{props.loadingText}</Box>}
    </Box>
  );
};

export default Loader;
