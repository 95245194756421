import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { TAGS } from "./constants";

export const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { mainApiUrl } = (api.getState() as RootState).settings.endpoints;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: mainApiUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // Use getState to pull the jwtToken and pass it in the headers to the api endpoint.
      const { jwtToken } = (getState() as RootState).auth;
      if (endpoint === "uploadFileToS3") {
        return headers;
      }
      headers.set("authorization", jwtToken);
      return headers;
    },
  });
  return rawBaseQuery(args, api, extraOptions);
};

export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    TAGS.TemplateIds,
    TAGS.Preprocessing_Rules,
    TAGS.Presigned_Url,
    TAGS.TemplateDetails,
    TAGS.Translation_History,
  ],
});
