import React, { Suspense } from "react";
import Loader from "components/loader";
import { Routes, Route } from "react-router-dom";
import Error404 from "pages/error-404";
import Landing from "pages/landing";
import TemplateDetails from "pages/template-details";
/* PLOP_IMPORT_ROUTES */
import DownloadTranslation from "pages/download-translation";
import ModifyTranslationRequest from "pages/modify-translation-request";
import CreateRule from "pages/create-rule";
import CreateFileTranslationRequest from "pages/create-file-translation-request";

const Router: React.FC = (): JSX.Element => {
  return (
    <Suspense fallback={<Loader loadingText="Loading..." />}>
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* PLOP_INJECT_ROUTES */}

        <Route path="/template">
          <Route path=":templateId">
            <Route path="" element={<TemplateDetails />} />
            <Route path="translate">
              <Route
                path="file/create"
                element={<CreateFileTranslationRequest />}
              />
              <Route path=":translationRequestId">
                <Route path="modify" element={<ModifyTranslationRequest />} />
                <Route path="download" element={<DownloadTranslation />} />
              </Route>
            </Route>
            <Route path="rule/create" element={<CreateRule />} />
          </Route>
        </Route>

        {/* Must be the last route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
