import { createSlice } from "@reduxjs/toolkit";
import { authenticate } from "./thunk";
import { IAuthState } from "./definitions";

const initialState: IAuthState = {
  isAuthenticated: false,
  jwtToken: "",
  roles: [],
  user: {
    alias: "",
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authenticate.pending, (state) => {
      state.isAuthenticated = false;
    });

    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.jwtToken = action.payload?.jwtToken ?? initialState.jwtToken;
      state.roles = action.payload?.roles ?? initialState.roles;
      state.user = action.payload?.user ?? initialState.user;
      state.isAuthenticated = state.jwtToken && state.user ? true : false;
    });

    builder.addCase(authenticate.rejected, (state) => {
      state.isAuthenticated = false;
    });
  },
});

export default authSlice.reducer;
