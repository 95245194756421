import React from "react";
import { useTranslation } from "react-i18next";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useAppSelector } from "app/store";
import { i18nStrings, profileActions } from "./config";
import styles from "./style.module.scss";
import logo from "./logo.svg";

const Header: React.FC = () => {
  const { fullName, email } = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <TopNavigation
      id="header"
      className={styles.header}
      i18nStrings={i18nStrings}
      identity={{
        href: "/",
        title: t("app_name"),
        logo: { src: logo, alt: t("app_name") },
      }}
      utilities={[
        {
          type: "button",
          variant: "link",
          external: true,
          text: "Suggest Feature",
          href: "https://sim.amazon.com/issues/create?template=89c306a1-865d-4d88-9652-81bc7761b605",
        },
        {
          type: "button",
          variant: "link",
          external: true,
          text: "Report Bug",
          href: "https://tt.amazon.com/create/templates/aa568bc8-127d-4276-8313-1f2c3eadb798",
        },
        {
          type: "menu-dropdown",
          text: fullName,
          description: email,
          iconName: "user-profile",
          items: profileActions,
        },
      ]}
    />
  );
};

export default Header;
