import { Amplify, AWSCloudWatchProvider, Logger } from "@aws-amplify/core";
import { SettingsResponse } from "features/settings/definitions";

const LOG = new Logger("LOG");

export const configureAmplify = (settings: SettingsResponse) => {
  const auth = prepareAuth(settings);
  const logger = prepareLogger(settings);

  Amplify.configure({
    ...(auth ?? {}),
    ...(logger ?? {}),
  });

  if (logger) {
    Amplify.register(LOG);
    LOG.addPluggable(new AWSCloudWatchProvider(logger));
  }
  return true;
};

const prepareAuth = (settings: SettingsResponse) => {
  if (!settings.auth) {
    return undefined;
  }

  const {
    domain,
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId,
    state,
  } = settings.auth;
  const redirectSignIn = window.location.protocol + "//" + window.location.host;

  const auth = {
    Auth: {
      userPoolId,
      userPoolWebClientId,
      region,
      identityPoolId,
      mandatorySignIn: true,
      oauth: {
        domain,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn,
        responseType: "code",
        state,
      },
    },
  };

  return auth;
};

const prepareLogger = (settings: SettingsResponse) => {
  if (!settings.logger) {
    return undefined;
  }

  const { groupName, streamName, region } = settings.logger;

  const LOGS_STREAM_NAME = `${new Date().toJSON().slice(0, 10)}-${streamName}`;

  const logger = {
    logGroupName: groupName,
    logStreamName: LOGS_STREAM_NAME,
    region: region,
  };

  return logger;
};

export default LOG;
